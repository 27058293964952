<template>
    <div>

        <h2 class="mb-2 headline"><b>Overall Statistics:</b> {{category}} </h2>
        <v-layout row wrap>
            <v-flex xs12 sm12 md12 lg8 class="pa-2" style="color: #424242">                
                <v-card light class="pa-2" style="border-radius: 5px; font-size: small">
                    <h6 class="title mt-2 ml-2 mb-0 pb-0 "><b>Statistics Card</b></h6>
                    <div class="body-1 mt-2 ml-2 mb-0 pa-0">A <b>summary</b> of your overall transaction 😎.</div>

                    <v-card-title primary-title class="pb-2 " style="color: #424242">
                        <v-layout row wrap>
                            <v-flex xs6 sm3 lg3 class="pa-2">                
                                <v-layout row >
                                    <v-flex xs5 elevation-4 class="py-3 purple darken-2 text-xs-center white--text" style="width: 60px; height: 60px; border-radius: 5px"> 
                                        <i class="fa-solid fa-money-bill-transfer fa-2x"></i>  
                                    </v-flex>
                                    <v-flex xs7 class="pa-1">
                                        <h6 class="body-1 mb-0 mt-0 pt-0" v-if="category == 'Savings'">Deposits</h6>
                                        <h6 class="body-1 mb-0 mt-0 pt-0" v-else>Transactions</h6>
                                        <h5 class="subheading  mb-0 pl-1"><b>{{ dashboard_info.data_three}}</b></h5>
                                    </v-flex>
                                </v-layout>             
                            </v-flex>
                            <v-flex xs6 sm3 lg3 class="pa-2">                
                                <v-layout row >
                                    <v-flex xs5 elevation-4 class="py-3 blue text-xs-center white--text" style="width: 60px; height: 60px; border-radius: 5px"> 
                                        <i class="fa-solid fa-sack-dollar fa-2x"></i>  
                                    </v-flex>
                                    <v-flex xs7 class="pa-1">
                                        <h6 class="body-1 mb-0 mt-0 pt-0" v-if="category == 'Savings'">Withdrawals</h6>
                                        <h6 class="body-1 mb-0 mt-0 pt-0" v-else>Items Bought</h6>
                                        <h5 class="subheading  mb-0 pl-1"><b>{{ dashboard_info.data_four}}</b></h5>
                                    </v-flex>
                                </v-layout>             
                            </v-flex>
                            <v-flex xs6 sm3 lg3 class="pa-2">                
                                <v-layout row >
                                    <v-flex xs5 elevation-4 class="py-3 red darken-3 text-xs-center white--text" style="width: 60px; height: 60px; border-radius: 5px"> 
                                        <i class="fa-solid fa-tags fa-2x"></i>  
                                    </v-flex>
                                    <v-flex xs7 class="pa-1">
                                        <h6 class="body-1 mb-0 mt-0 pt-0" v-if="category == 'Savings'">Campaigns</h6>
                                        <h6 class="body-1 mb-0 mt-0 pt-0" v-else>Items Pending</h6>
                                        <h5 class="subheading  mb-0 pl-1"><b>{{ dashboard_info.data_five}}</b></h5>
                                    </v-flex>
                                </v-layout>             
                            </v-flex>
                            <v-flex xs6 sm3 lg3 class="pa-2">                
                                <v-layout row >
                                    <v-flex xs5 elevation-4 class="py-3 indigo darken-3 text-xs-center white--text" style="width: 60px; height: 60px; border-radius: 5px"> 
                                        <i class="fa-solid fa-usd fa-2x"></i>  
                                    </v-flex>
                                    <v-flex xs7 class="pa-1">
                                        <h6 class="body-1 mb-0 mt-0 pt-0" v-if="category == 'Savings'">Balance</h6>
                                        <h6 class="body-1 mb-0 mt-0 pt-0" v-else>Credit</h6>
                                        <h5 class="subheading  mb-0 pl-1"><b>{{ dashboard_info.data_six}}</b></h5>
                                    </v-flex>
                                </v-layout>             
                            </v-flex>
                        </v-layout>
                    </v-card-title>
                </v-card>
            </v-flex>
            
            <v-flex xs12 md6 lg2 class="pa-2">
                <v-card class="" style="border-radius: 5px; font-size: small">
                    <v-layout row >
                        <v-flex xs10 md8>
                            <v-btn  fab color="mt-2 ml-3 mb-0 purple darken-2 white--text">
                                <i class="fa-solid fa-money-bill-transfer fa-2x"></i>
                            </v-btn>
                        </v-flex>
                        <v-flex xs2 class="text-xs-right">
                            <v-btn flat fab color="mt-2 ml-3 mb-0 ">
                                <i class="fa-solid fa-ellipsis-vertical fa-2x"></i>
                            </v-btn>
                        </v-flex>
                    </v-layout>

                    <v-card-title primary-title class="pb-0 mt-0 pt-4" style="color: #424242">
                        <div>
                            <h6 class="body-2 mb-0" v-if="category == 'Savings'">Total Deposits</h6>
                            <h6 class="body-2 mb-0" v-else>Items Bought</h6>
                            <h2 class="mb-0 pl-1"><b>{{ dashboard_info.data_one }} F CFA</b></h2>
                            <!-- <div class="green--text pl-1"><b>+15%</b></div> -->
                            <div class="caption mt-0 mb-0 pb-2">Stats. so far</div>
                        </div>
                    </v-card-title>
                </v-card>
            </v-flex>
            <v-flex xs12 md6 lg2 class="pa-2">

                <v-card class="" style="border-radius: 5px; font-size: small">
                    <v-layout row >
                        <v-flex xs10 md8>
                            <v-btn  fab color="mt-2 ml-3 mb-0 blue darken-2 white--text">
                                <i class="fa-solid fa-sack-dollar fa-2x"></i>
                            </v-btn>
                        </v-flex>
                        <v-flex xs2 class="text-xs-right">
                            <v-btn flat fab color="mt-2 ml-3 mb-0 ">
                                <i class="fa-solid fa-ellipsis-vertical fa-2x"></i>
                            </v-btn>
                        </v-flex>
                    </v-layout>

                    <v-card-title primary-title class="pb-0 mt-0 pt-4" style="color: #424242">
                        <div>
                            <h6 class="body-2 mb-0" v-if="category == 'Savings'">Total Withdrawals</h6>
                            <h6 class="body-2 mb-0" v-else>Items Pending</h6>
                            <h2 class="mb-0 pl-1"><b>{{ dashboard_info.data_two }} F CFA</b></h2>
                            <!-- <div class="red--text pl-1"><b>-5%</b></div> -->
                            <div class="caption mt-0 mb-0 pb-2">Stats. so far</div>
                        </div>
                    </v-card-title>
                </v-card>
            </v-flex>

            <v-flex xs12 sm12 md12 lg8 class="pa-2" style="border-radius: 5px; font-size: small; color: #424242">                
                <v-card light class="pa-2" style="">
                    <h6 class="title mt-2 ml-2 mb-0 pb-0 "><b>My Activities(Past 30 days)</b></h6>
                    <v-card-title v-for="item in activity_logs" :key="item.id" class="pb-0 " style="border-bottom: 1px solid grey; color: #424242">
                        <p class="mb-0" style="">
                            <span v-html="item.activity_message"></span>
                            <b style="margin-left: 10px">({{ item.get_date_str }})</b>
                        </p>
                    </v-card-title>
                </v-card>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
    import axios from 'axios'
    // https://vue-chartjs.org/guide/#creating-your-first-chart
    import { Bar, Pie, Doughnut } from 'vue-chartjs/legacy'
    import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement } from 'chart.js'

    ChartJS.register(Title, Tooltip, Legend, BarElement, ArcElement, CategoryScale, LinearScale)

    export default {
        barName: 'BarChart',
        pieName: 'PieChart',
        components: { Bar, Pie, Doughnut },
        props: {
            barChartId: {
            type: String,
            default: 'bar-chart'
            },
            pieChartId: {
            type: String,
            default: 'pie-chart'
            },
            datasetIdKey: {
            type: String,
            default: 'label'
            },
            width: {
            type: Number,
            default: 400
            },
            height: {
            type: Number,
            default: 200
            },
            cssClasses: {
            default: '',
            type: String
            },
            styles: {
            type: Object,
            default: () => {}
            },
            plugins: {
            type: Object,
            default: () => {}
            }
        },

        data() {
            return {
                dashboard_info: [],
                activity_logs: [],
                page: 1,
                category: "",
                campaigns: [],
                titleColor: 'blue--text',

                barChartData: {
                    labels: [ 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
                    datasets: [ 
                        { 
                            backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16'],
                            data: [40, 20, 12, 20, 12, 20, 12] 
                        }
                    ]
                },
                pieChartData: {
                    labels: ['VueJs', 'EmberJs', 'ReactJs', 'AngularJs'],
                    datasets: [
                        {
                            backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16'],
                            data: [40, 20, 80, 10]
                        }
                    ]
                },
                barChartOptions: {
                    responsive: true,
                    maintainAspectRatio: false,
                    title: {
                        display: true,
                        text: 'Chart.js Bar Chart'
                    }
                },
                pieChartOptions: {
                    responsive: true,
                    maintainAspectRatio: false,
                    title: {
                        display: true,
                        text: 'Chart.js Pie Chart'
                    }
                }

            }
        },

        methods:{
            getSelectedCategory(){
                var category = this.$route.params.types
                if (category == "save"){
                    this.category = "Savings"
                }
                else if (category == "buy"){
                    this.category = "Market Place"
                }
                else if (category == "contributions"){
                    this.category = "Njangi"
                }
                else if (category == "funds"){
                    this.category = "Funding"
                }
                else if (category == "observations"){
                    this.category = "Monitor"
                }

            },
            
            async getDashboardInfo(){
                await axios
                    .get('/api/v1/home/get/user/dashboard/'+this.category+'/')
                    .then(response => {
                        this.dashboard_info = response.data
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })

            },

            async getActivityLog(){
                await axios
                    .get('/api/v1/home/get/activity_logs/'+this.category+'/')
                    .then(response => {
                        this.activity_logs = response.data
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })

            },
            
        },

        mounted(){
            // executes these after the page has been mounted
            document.title = "PettyCash | Overall Dashboard"
            this.getSelectedCategory()
            this.getActivityLog()
            this.getDashboardInfo()
            this.$store.commit('setCloseURL', "/")
        },

        watch: {
            '$route': function(to, from) {
                this.getSelectedCategory()
                this.getActivityLog()
                this.getDashboardInfo()
            }
        },
    }
</script>

<style scoped>
    blink {
    -webkit-animation: 4s linear infinite condemned_blink_effect; /* for Safari 4.0 - 8.0 */
    animation: 4s linear infinite condemned_blink_effect;
    }

    /* for Safari 4.0 - 8.0 */
    @-webkit-keyframes condemned_blink_effect {
    0% {
        visibility: hidden;
    }
    50% {
        visibility: hidden;
    }
    100% {
        visibility: visible;
    }
    }

    @keyframes condemned_blink_effect {
    0% {
        visibility: hidden;
    }
    50% {
        visibility: hidden;
    }
    100% {
        visibility: visible;
    }
    }

</style>

